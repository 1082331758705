import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';

import { TabItem, Tabs, TabsAlignment, TabsVariant } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { useCurrentStateAndParams, useRouter } from 'common/router';

import { GroupAppKey } from 'store/groups/types';
import {
  selectGroupIdBySlug,
  selectGroupUpdates,
  selectInstalledApplications,
} from 'store/selectors';

import { classes, st } from './Navigation.st.css';

interface INavigationProps extends TPAComponentProps {}

export function Navigation(props: INavigationProps) {
  const router = useRouter();
  const { state, params } = useCurrentStateAndParams();
  const { isRTL, isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const groupUpdates = useSelector(selectGroupUpdates(groupId));
  const applications = useSelector(selectInstalledApplications(groupId));

  const tabs = React.useMemo<TabItem[]>(() => {
    return applications.map((application) => {
      let route = getApplicationRoute(application.key!);
      const counter = route?.data?.counter as keyof typeof groupUpdates;
      const updates = groupUpdates[counter];

      let href;
      if (
        application.key === GroupAppKey.CUSTOM_APP ||
        application.key === GroupAppKey.CUSTOM_APP_2 ||
        application.key === GroupAppKey.CUSTOM_APP_3
      ) {
        route = getApplicationRoute(GroupAppKey.CUSTOM_APP);
        if (isSSR) {
          href = router.href(
            getApplicationRoute(GroupAppKey.CUSTOM_APP)!.name as string,
            {
              ...params,
              tabId: application.key?.toLowerCase(),
            },
            { absolute: true },
          );
        }
      } else {
        if (isSSR) {
          href = router.href(
            getApplicationRoute(application.key!)!.name as string,
            params,
            { absolute: true },
          );
        }
      }

      return {
        id: application.key,
        title:
          application.customName || t(route?.data?.title, { count: undefined }),
        href,
      };
    });
  }, [
    isSSR,
    JSON.stringify(params),
    JSON.stringify(groupUpdates),
    applications,
  ]);

  const activeTab = tabs.findIndex((tab) => {
    if (
      tab.id === GroupAppKey.CUSTOM_APP ||
      tab.id === GroupAppKey.CUSTOM_APP_2 ||
      tab.id === GroupAppKey.CUSTOM_APP_3
    ) {
      return tab.id?.toLowerCase() === params?.tabId;
    } else {
      return tab.id === state.data?.application;
    }
  });

  return (
    <div className={st(classes.root, { mobile: isMobile }, props.className)}>
      <Tabs
        data-hook="tabsHook"
        className={classes.tabs}
        items={tabs}
        activeTabIndex={activeTab}
        alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
        variant={isMobile ? TabsVariant.fullWidth : TabsVariant.fit}
        onTabClick={handleTabClick}
      />
    </div>
  );

  function getApplicationRoute(key: GroupAppKey) {
    if (
      key === GroupAppKey.CUSTOM_APP ||
      key === GroupAppKey.CUSTOM_APP_2 ||
      key === GroupAppKey.CUSTOM_APP_3
    ) {
      return router.states['group.custom.tab'];
    }

    return Object.values(router.states).find(
      (route) => route.data?.application === key,
    );
  }

  function handleTabClick(index: number) {
    const tab = tabs[index];

    if (
      tab.id === GroupAppKey.CUSTOM_APP ||
      tab.id === GroupAppKey.CUSTOM_APP_2 ||
      tab.id === GroupAppKey.CUSTOM_APP_3
    ) {
      router.go('group.custom.tab', { tabId: tab?.id?.toLowerCase() });
    } else {
      router.go(getApplicationRoute(tab.id as GroupAppKey)!.name as string);
    }

    bi.report(
      groupsTabClicked({
        group_id: groupId,
        origin: 'tabs',
        name: tab.id,
      }),
    );
  }
}
