import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Spinner } from 'wix-ui-tpa';

import {
  selectGroupFetchStatus,
  selectGroupIdBySlug,
  selectIsGroupRejected,
} from 'store/selectors';

import { useCurrentStateAndParams } from 'common/router';
import { Container } from 'wui/Container';

import { Header } from '../Header/Header';
import { GROUP_PAGE_ROOT } from '../dataHooks';

import { ApplicationPage } from './ApplicationPage';
import { GroupNotFound, GroupRejected } from './ErrorStates';

import { classes } from './Group.st.css';

export function Group() {
  const { isMobile, isPreview, dimensions, isRTL } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const status = useSelector(selectGroupFetchStatus(params.slug));
  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const isRejected = useSelector(selectIsGroupRejected(groupId));

  if (status.loading) {
    return (
      <div
        data-hook={GROUP_PAGE_ROOT}
        dir={isRTL ? 'rtl' : undefined}
        className={classes.root}
      >
        <Container className={classes.placeholder}>
          <Spinner isCentered />
        </Container>
      </div>
    );
  }

  if (status.error || !params.slug) {
    return (
      <div
        data-hook={GROUP_PAGE_ROOT}
        dir={isRTL ? 'rtl' : undefined}
        className={classes.root}
      >
        <Container className={classes.placeholder} verticalAlign="middle">
          <GroupNotFound />
        </Container>
      </div>
    );
  }

  if (isRejected) {
    return (
      <div
        data-hook={GROUP_PAGE_ROOT}
        dir={isRTL ? 'rtl' : undefined}
        className={classes.root}
      >
        <Container className={classes.placeholder}>
          <GroupRejected />
        </Container>
      </div>
    );
  }

  return (
    <div
      data-hook={GROUP_PAGE_ROOT}
      dir={isRTL ? 'rtl' : undefined}
      className={classes.root}
      style={{ width: isPreview && isMobile ? dimensions.width : undefined }}
    >
      <Header />
      <ApplicationPage />
    </div>
  );
}
