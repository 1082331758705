import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';

import { Modal, ModalProps } from '../Modal';
import { DIALOG_CANCEL_BUTTON, DIALOG_OK_BUTTON } from './dataHooks';
import { BlackAndWhiteButton } from '../BlackAndWhiteButton';

export interface DialogProps extends ModalProps {
  title?: string;
  caption?: string;
  cancelLabel?: string;
  okLabel?: string;
  onOkClick?(): void;
  onCancelClick?(): void;
  buttons?: React.ReactNode;
  footer?: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    caption,
    cancelLabel,
    okLabel,
    onCancelClick,
    onOkClick,
    buttons,
    children,
    footer,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} data-hook={props['data-hook']}>
      {title ? <Modal.Header title={title} subtitle={caption} /> : null}
      {children}
      <Modal.Buttons>
        {buttons || (
          <>
            <BlackAndWhiteButton
              onClick={onCancelClick || onClose}
              priority={ButtonPriority.secondary}
              data-hook={DIALOG_CANCEL_BUTTON}
            >
              {cancelLabel || 'Cancel'}
            </BlackAndWhiteButton>
            <BlackAndWhiteButton
              onClick={onOkClick}
              priority={ButtonPriority.primary}
              data-hook={DIALOG_OK_BUTTON}
            >
              {okLabel || 'OK'}
            </BlackAndWhiteButton>
          </>
        )}
      </Modal.Buttons>
      {footer}
    </Modal>
  );
};

Dialog.displayName = 'Dialog';
